import { createApp } from 'vue'
import { createStore } from 'vuex'
import App from './App.vue'
import router from './router'
import 'bootstrap/scss/bootstrap.scss'
import '@/assets/main.scss'
import {ethers} from "ethers";
import nft from "../../artifacts/contracts/CryptoRATOriginals.sol/CryptoRATOriginals.json";
import stake from '../../artifacts/contracts/NftStakeV3.sol/NftStakeV3.json';
import shop from '../../artifacts/contracts/RatShop.sol/RatShop.json'
import diceAbi from '../../indexers/abi/ratdice.json'
import erc20Abi from '../../indexers/abi/erc20.json'
import { Multicall } from 'pilum';

import * as bch_config from "../config";
import * as doge_config from "../../nightmares/config";
import {ratShop} from "../../nightmares/config";

export let app = createApp(App);
app.config.globalProperties.window = window;
app.config.globalProperties.api_url = 'https://api.cryptor.at';
app.config.globalProperties.tokenEnabled = true;
app.config.globalProperties.contracts = {
    nftContract: undefined,
    stakeContract: undefined,
    shopContract: undefined,
};

const store = createStore({
    state: {
        settings: {
            bch: bch_config,
            doge: doge_config,
        },
        preferredNetwork: 'doge',
        connected: false,
        address: null,
        signer: null,
        provider: null,
        nftContract: null,
        marketContract: null,
        stakeContract: null,
        nexusContract: null,
        shopContract: null,
        currentNetwork: null,
        requiredNetwork: 2000,
        stakeEarnings: 0,
        stakePower: 0,
        nexusBalance: 0,
        alphas: [],
        wallet: [],
        lns: [],
        loaded: 0,
        nfts: [],
        shelves: [],
        socketConnecting: false,
        socket: null,
        catalogLoading: false,
        catalog: [],
        withdraws: [],
        credits: [],
    },
    mutations: {
        provider (state, provider) {
            state.provider = provider;
        },
        connect (state, signer) {
            state.connected = true;
            state.signer = signer;
        },
        address (state, address) {
            state.address = address.toLowerCase();
        },
        lns (state, data) {
            state.lns[data.address] = data.name;
        },
        alpha (state, alphaData) {
            state.alphas[alphaData.address] = alphaData;
        },
        nftContract (state, nft) {
            state.nftContract = nft;
        },
        marketContract (state, market) {
            state.marketContract = market;
        },
        socketConnecting(state, connecting) {
            state.socketConnecting = connecting
        },
        socket (state, socket) {
            state.socket = socket;
        },
        stakeContract (state, stake) {
            state.stakeContract = stake;
        },
        nexusContract (state, nexus) {
            state.nexusContract = nexus;
        },
        shopContract (state, shop) {
            state.shopContract = shop;
        },
        currentNetwork (state, netId) {
            state.currentNetwork = netId;
        },
        nft (state, nftData) {
            state.nfts[nftData.id] = nftData;
        },
        withdraws (state, withdraws) {
            state.withdraws = withdraws
        },
        credits (state, credits) {
            state.credits = credits
        },
        walletNft (state, nftData) {
            if(state.wallet.includes(nftData.id)) {
                return;
            }
            state.wallet.push(nftData.id);
            state.wallet.sort(function (a, b) {
                if (a === b) {
                    return 0;
                }
                if (a > b) {
                    return 1;
                }
                return -1;
            });
        },
        loaded(state) {
            state.loaded += 1;
        },
        catalog(state, item) {
            state.catalog.push(item);
        },
        stakeEarnings(state, earnings) {
            state.stakeEarnings = parseFloat(ethers.utils.formatEther(earnings)).toFixed(3);
        },
        nexusBalance(state, balance) {
            state.nexusBalance = parseFloat(ethers.utils.formatEther(balance)).toFixed(3);
        },
        stakePower(state, power) {
            state.stakePower = power;
        },
        shelve(state, shelve) {
            for(var i in state.shelves) {
                if(shelve.id === state.shelves[i].id) {
                    return;
                }
            }
            state.shelves.push(shelve);
            state.shelves.sort((a, b) => {
                if (a.order === b.order) {
                    return 0;
                }
                if (a.order < b.order) {
                    return 1;
                }
                return -1;
            })
        }
    },
    actions: {
        initiateWallet (state) {
            let defaultProvider = new ethers.providers.JsonRpcProvider(state.getters.config.rpc, state.getters.config.chainId);
            state.commit('provider', defaultProvider);
            state.dispatch('connectContracts', defaultProvider);

            let provider = new ethers.providers.Web3Provider(window.ethereum, "any");
            return provider.send("eth_accounts", []).then((accounts) => {
                state.commit('address', accounts[0]);
                return provider.getNetwork().then((netId) => {
                    state.commit('currentNetwork', netId.chainId);
                    state.commit('connect', provider.getSigner());
                    if(netId.chainId === state.getters.config.chainId) {
                        state.commit('provider', provider);
                    } else {
                        provider.send("wallet_switchEthereumChain", [{
                            chainId: '0x7d0'
                        }]);
                    }
                });
            });
        },
        connectWallet (state) {
            let provider = new ethers.providers.Web3Provider(window.ethereum, "any");
            return provider.send("eth_requestAccounts", []).then(() => {
                state.dispatch('initiateWallet');
            });
        },
        connectContracts (state, provider) {
            state.commit('nftContract', new ethers.Contract(state.getters.config.nftCA, nft.abi, provider));
            state.commit('stakeContract', new ethers.Contract(state.getters.config.stakingProxyCA, stake.abi, provider));
            state.commit('shopContract', new ethers.Contract(state.getters.config.ratShop, shop.abi, provider))
        },
        switchNetwork(store) {
            return window.ethereum.request({
                method: 'wallet_switchEthereumChain',
                params: [{
                    chainId: ethers.utils.hexlify(store.getters.config.chainId)
                }],
            });
        },
        loadLNS(store, address) {
            let normalised = address.toLowerCase();
            if(normalised in store.state.lns) {
                return store.state.lns[normalised];
            }
            const provider = new ethers.providers.JsonRpcProvider(store.getters.config.rpc, {
                chainId: store.getters.config.chainId,
                ensAddress: store.getters.config.ensAddress,
            });
            return provider.lookupAddress(normalised).then(name => {
                store.commit('lns', {address: normalised, name: name});
                // store.dispatch('socketSendDetails');
                return name;
            });
        },
        async startSocket(store) {
            console.log('Starting socket');
            if(store.state.socketConnecting === true) {
                return
            }
            store.commit('socketConnecting', true);
            let webSocket = new WebSocket('ws://localhost:3001');
            webSocket.onopen = () => {
                store.dispatch('socketSendDetails');
                store.commit('socketConnecting', false);
            };
            webSocket.onmessage = (event) => {
                let data = JSON.parse(event.data)
                switch(data._event) {
                    case 'withdraws':
                        store.commit('credits', data._credits)
                        store.commit('withdraws', data._withdraws)
                        break
                }
                console.log(event);
            }
            webSocket.onclose = () => {
                console.log('Socket closed');
            }
            store.commit('socket', webSocket);
        },
        socketSendDetails(store) {
            if(!store.state.socket) {
                return;
            }

            let event = {};
            event._event = 'details';
            event._address = store.state.address;
            event._network = 2000;
            if(store.state.lns[store.state.address]) {
                event._username = store.state.lns[store.state.address];
            }
            store.state.socket.send(JSON.stringify(event));
        },
        socketWithdraw(store, token, amount) {
            if(!store.state.socket) {
                return;
            }

            let event = {};
            event._event = 'token.withdraw.generate';
            event._token = token;
            event._amount = amount;
            store.state.socket.send(JSON.stringify(event));
        },
        loadAlpha(store, address) {
            if(address in store.state.alphas) {
                return store.state.alphas[address];
            }
            return store.state.nftContract.alpha(address).then((id) => {
                if(id > 0) {
                    return store.state.nftContract.tokenURI(id).then((uri) => {
                        return fetch(uri).then((response) => {
                            return response.json();
                        }).then((result) => {
                            var data = {address: address, id: id.toString(), image: result.image};
                            store.commit('alpha', data);
                            return data;
                        });
                    })
                }
            });
        },
        async loadShop(store) {
            if(store.state.shopContract) {
                let multicall = new Multicall({
                    provider: new ethers.providers.JsonRpcProvider(
                        store.getters.config.rpc,
                        store.getters.config.chainId
                    )
                });
                let calls = [];
                let shelveIDs = [
                    //100004, 100005, 100006, 100003, 100007,
                    //27, 28, 30, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12,
                   // 16, 13, 15, 14, 17, 20, 21, 22,
                    //34, 26, 29, 31, 32, 33,
                    //52, 53, 54, 55, 56, 57, 58, 59, 60, 61, 62, 63,
                    64, 65, 66, 67, 68, 69, 70, 71, 72
                ];
                for (let i in shelveIDs) {
                    calls.push({
                        reference: 'shop',
                        address: ratShop,
                        abi: shop.abi,
                        method: 'shelves',
                        params: [shelveIDs[i]],
                        value: 0,
                    })
                }
                let { results } = await multicall.call(calls);
                for(let j in results) {
                    let shelve = ethers.utils.defaultAbiCoder.decode(shop.abi[14].outputs, results[j].returnData[1])
                    let shelveItem = Object.assign({}, shelve);
                    shelveItem.normalisedCost = ethers.utils.formatEther(shelveItem.cost);
                    shelveItem.tokenName = 'WDOGE'
                    switch(shelveItem.token) {
                        case '0xB9fcAa7590916578087842e017078D7797Fa18D0':
                            shelveItem.tokenName = 'DTools'
                            break
                        case '0x35EA0c670eD9f54Ac07B648aCF0F2EB173A6012D':
                            shelveItem.tokenName = 'TDH'
                            break
                    }

                    fetch('https://api.cryptor.at/nightmares/nft/99999_' + shelveItem.traitType).then((result) => {
                        return result.json();
                    }).then(data => {
                        shelveItem.id = shelveIDs[j];
                        shelveItem.order = j;
                        shelveItem.metadata = data;
                        shelveItem.bought = false;
                        store.commit('shelve', shelveItem);
                    });
                }
            }
        },
        loadWallet(store) {
            if(store.state.address && store.state.nftContract) {
                if(store.state.nexusContract) {
                    store.state.nexusContract.balanceOf(store.state.address).then(balance => {
                        store.commit('nexusBalance', balance);
                    });
                }
                store.state.stakeContract.earnings(store.state.address).then(earnings => {
                    store.commit('stakeEarnings', earnings);
                });
                store.state.nftContract.power(store.state.address).then(power => {
                    store.commit('stakePower', power);
                });
                store.state.nftContract.walletOfOwner(store.state.address).then(async (result) => {
                    let multicall = new Multicall({
                        provider: new ethers.providers.JsonRpcProvider(
                            store.getters.config.rpc,
                            store.getters.config.chainId
                        )
                    });
                    let calls = [];
                    for (let i in result) {
                        calls.push({
                            reference: 'nft',
                            address: store.getters.config.nftCA,
                            abi: nft.abi,
                            method: 'tokenURI',
                            params: [result[i]],
                            value: 0,
                        })
                    }
                    let { results } = await multicall.call(calls);
                    for (let i in results) {
                        let tokenUri = ethers.utils.defaultAbiCoder.decode(nft.abi[49].outputs, results[i].returnData[1])
                        fetch(tokenUri).then((result) => {
                            return result.json();
                        }).then(data => {
                            store.commit('loaded');
                            store.commit('nft', {
                                metadata: data,
                                id: result[i],
                                slot: data.slot,
                                slotName: data.slotName,
                                image: data.image,
                                trait_id: data.trait_id,
                            });
                            store.commit('walletNft', {id: parseInt(result[i])});
                        });
                    }
                });
            }
        }
    },
    getters: {
        signer: (state) => state.signer,
        provider: (state) => state.provider,
        config: (state) => {
            switch(state.preferredNetwork) {
                case 'bch':
                    return state.settings.bch;
                case 'doge':
                    return state.settings.doge;
                default:
                    return state.settings.bch;
            }
        },
        freshNftContract: () => {
            let provider = new ethers.providers.Web3Provider(window.ethereum, "any");
            return new ethers.Contract(doge_config.nftCA, nft.abi, provider.getSigner());
        },
        freshStakeContract: () => {
            let provider = new ethers.providers.Web3Provider(window.ethereum, "any");
            return new ethers.Contract(doge_config.stakingProxyCA, stake.abi, provider.getSigner())
        },
        freshShopContract: () => {
            let provider = new ethers.providers.Web3Provider(window.ethereum, "any");
            return new ethers.Contract(doge_config.ratShop, shop.abi, provider.getSigner())
        },
        freshDiceContract: () => {
            let provider = new ethers.providers.Web3Provider(window.ethereum, "any");
            return new ethers.Contract('0x9D6388f88a73e5974CEdabfa907375a9445e576c', diceAbi, provider.getSigner())
        },
        freshTokenContract: () => {
            let provider = new ethers.providers.Web3Provider(window.ethereum, "any");
            return new ethers.Contract('0x7B4328c127B85369D9f82ca0503B000D09CF9180', erc20Abi, provider.getSigner())
        }
    },
});

app.use(store);
app.use(router);
app.mount('#app')
