module.exports = {
    chainId: 2000,
    baseSupply: 1000,
    currency: 'WDOGE',
    power: 'Nexus',
    powerIcon: '🪐',
    chainName: 'DogeChain',
    ensAddress: '0x834C46666c1dE7367B252682B9ABAb458DD333bf',
    rpc: 'https://rpc.dogechain.dog',
    nftCA: '0xa2E57fa488cf272c87b066e2a3E0672c0c58784d',
    nftLogicCA: '0x769671EbDC17653dAecf6Fe9Ab9dE7CeC04822d3',
    stakingProxyCA: '0x1AC58989f18AAD81E602e94cdd3B30b293961c10',
    stakingLogicCA: '0xD234791b3B13c6b4D2Ad93D4aDCB67Bcd52564f1',
    traitSwap: '0xB9be6C3c10145275fBeDfd9Ca831888ad5B29B84',
    ratShop: '0xCD16daBdC8355FCa27ecdD0755fc0225cd81bf0B',
}
