/**/
module.exports = {
    chainId: 10000,
    baseSupply: 10025,
    currency: 'BCH',
    power: 'IQ',
    powerIcon: '🧠',
    chainName: 'SmartBCH',
    ensAddress: '0xCfb86556760d03942EBf1ba88a9870e67D77b627',
    rpc: 'https://smartbch.greyh.at/',
    nftCA: '0x91bc4F61d45Dfbb9C277CDF6928923Cb46e8A2E9',
    nftLogicCA: '0xB34364986EdcE0A43b602EA57318b7eb31C26057',
    stakingProxyCA: '0x9E24f5ADd973c0E97b385432aF643E582ea673dF',
    stakingLogicCA: '0xA4D74E6B37D63BB4eF56BD155711530304FE748F',
    marketProxyCA: '0x4b488ad91dd627B23915Ba52D622a953c83CE35F',
    // marketLogicCA: '0xc38Bff21809DBCA7D861D380398b7546a9ad16Df',
    marketLogicCA: '0x7b404921FE05bFB3820913AB3abb5aeBDD9054Dc',
    nexusToken: '0x3743ec0673453e5009310c727ba4eaf7b3a1cc04',  // WBCH
    traitMint: '0x03168C53a1Bc9460930c91639D5fa8c213E47cE8',
    traitSwap: '0xE6AC53F8FA12A3F8BAe234f9e4A6B67EA3D75998',
}
/*/
module.exports = {
    nftCA: '0x746268A7D647cCdb8b5556c839C0f45a753eacc3',
    nftLogicCA: '0x377B98Cfa7F8c6c275E0389f565201845E51358c',
    stakingProxyCA: '0xfd245835Cc8478fb863E4E8d9EBc8d2fC662e621',
    stakingLogicCA: '0xA92e99B392B5A2Ae3b3c601879b5578922e56124',
    marketProxyCA: '0x4983359D1A44275edf520FD1158E1F74a7748B28',
    marketLogicCA: '0xca59D5F1257dF5C601349876Bb47dfa845063Aff',
    nexusToken: '0x75773EE65AD84Be047b6933b60763437b140d2b0',
    rps: '0xB76030f9C2b584b75D5571fAdAB43879d9e672b4',
    traitMint: '0xC7742a8b9A3A9a647D37229065e9E69d98039334'
}
/**/