<template>
  <router-link class="nav-link" v-if="name != null" v-bind:to="'/rat/landofmischief/' + address">
    <img v-if="placeholder && !alpha" src="https://api.cryptor.at/nightmares/rat/0" width="32" height="32" class="alpha-image nft"/>
    <img v-if="alpha" v-bind:src="alpha.image" width="32" height="32" class="alpha-image"/>&nbsp;
    {{ name }}
  </router-link>
  <router-link class="nav-link" v-if="name == null && short != 'yes'" v-bind:to="'/rat/landofmischief/' + address">
    <img v-if="placeholder && !alpha" src="https://api.cryptor.at/nightmares/rat/0" width="32" height="32" class="alpha-image nft"/>
    <img v-if="alpha" v-bind:src="alpha.image" width="32" height="32" class="alpha-image"/>&nbsp;
    {{ address }}
  </router-link>
  <router-link class="nav-link" v-if="name == null && short == 'yes'" v-bind:to="'/rat/landofmischief/' + address">
    <img v-if="placeholder && !alpha" src="https://api.cryptor.at/nightmares/rat/0" width="32" height="32" class="alpha-image nft"/>
    <img v-if="alpha" v-bind:src="alpha.image" width="32" height="32" class="alpha-image"/>&nbsp;
    {{ address.substr(0, 4) }}...{{ address.substr(address.length - 4, address.length) }}
  </router-link>
</template>

<script>
export default {
  data() {
    return {
      name: null,
      alpha: null,
      tag: null,
    }
  },
  props: ['address', 'short', 'mm', 'placeholder'],
  mounted() {
    this.updateDisplay();
  },
  watch: {
    address: function() {
      this.updateDisplay();
    },
    '$store.state.provider': function() {
      this.updateDisplay();
    }
  },
  methods: {
    updateDisplay: function() {
      if(this.address && this.address.substr(0, 2) === '0x' && this.$store.state.nftContract) {
        this.tag = null;
        switch (this.address.toLowerCase()) {
          case '0x657061bf5D268F70eA3eB1BCBeb078234e5Df19d'.toLowerCase():
          case '0x3b968177551a2aD9fc3eA06F2F41d88b22a081F7'.toLowerCase():
            this.tag = 'OASIS';
            break;
        }
        this.$store.dispatch('loadAlpha', this.address).then((alpha) => {
          this.alpha = alpha;
        });
        this.$store.dispatch('loadLNS', this.address).then((name) => {
          this.name = name;
        });
      }
    }
  }
};
</script>