<template>
  <Layout></Layout>

  <router-view/>

  <footer class="footer bg-black small text-end text-white-50">
    <div class="container px-4 px-lg-5">
      <a href="https://dogedomains.wf/" target="_blank">
        <img src="@/assets/dns.svg" height="130" />
      </a>
      &nbsp;
      <a href="https://shadowkite.io/" target="_blank">
        <img src="@/assets/shadowkite-white-small.png" class="img-fluid shadowkite-logo-dark" />
      </a>
    </div>

  </footer>
  <div class="text-center" style="color: #777777; background: #000000">
    Copyright © 2021-2024 Shadowkite®. All rights reserved.
  </div>
</template>

<script>
import Layout from "@/components/Layout";

export default {
  components: { Layout },
  mounted() {
    this.$store.dispatch('initiateWallet');
    window.ethereum.on('accountsChanged', () => {
      this.$store.dispatch('initiateWallet');
    });
    window.ethereum.on('chainChanged', () => {
      this.$store.dispatch('initiateWallet');
    });
  }
}
</script>