<template>
  <nav class="navbar navbar-expand-lg navbar-light fixed-top" id="mainNav">
    <div class="container px-4 px-lg-5">
      <router-link class="navbar-brand" to="/">Crypto<span style='color: red'>R.AT</span>: Nightmares</router-link>
      <button class="navbar-toggler navbar-toggler-right" type="button" data-bs-toggle="collapse" data-bs-target="#navbarResponsive" aria-controls="navbarResponsive" aria-expanded="false" aria-label="Toggle navigation">
        Menu
        <i class="fas fa-bars"></i>
      </button>

      <div class="collapse navbar-collapse" id="navbarResponsive">
        <ul class="navbar-nav ms-auto">
          <li class="nav-item">
            <MetamaskAddress v-if="$store.state.address" mm="yes" v-bind:address="$store.state.address" short="yes"></MetamaskAddress>
            <span class="metamask-button btn btn-secondary" v-on:click="$store.dispatch('connectWallet')" v-if="!$store.state.connected">
              <img src="@/assets/metamask.svg" width="32" height="32">
              Connect
            </span>
            <!--span class="metamask-button btn btn-secondary" v-on:click="$store.dispatch('switchNetwork');" v-if="$store.state.requiredNetwork !== $store.state.currentNetwork">Switch network</span-->
          </li>
          <li class="nav-item" v-if="$store.state.connected">
            <router-link to="/shop" class="nav-link">Shop</router-link>
          </li>
          <li class="nav-item">
            <router-link to="/wallet" class="nav-link">My $RATs</router-link>
          </li>
          <li class="nav-item" v-if="$store.state.connected">
            <router-link to="/rat/traits" class="nav-link">Traits</router-link>
          </li>
          <li class="nav-item">
            <router-link to="/rat/landofmischief" class="nav-link">Land of mischief</router-link>
          </li>
          <li class="nav-item" v-if="$store.getters.config.chainId === 10000">
            <router-link to="/rat/iq" class="nav-link">IQ</router-link>
          </li>
          <li class="nav-item" v-if="$store.getters.config.chainId === 2000">
            <router-link to="/monsters" class="nav-link">Monster Chart</router-link>
          </li>
          <li class="nav-item" v-if="$store.getters.config.chainId === 10000">
            <router-link to="/collectibles" class="nav-link">Collectibles</router-link>
          </li>
          <li class="nav-item" v-if="$store.getters.config.chainId === 10000">
            <router-link to="/puzzle" class="nav-link">Puzzles</router-link>
          </li>
        </ul>
      </div>
    </div>
  </nav>
</template>
<script>
import MetamaskAddress from "@/components/MetamaskAddress";
export default {
  components: {MetamaskAddress},
  data() {
    return {
      ratNumber: null,
      theme: true,
      tokenEnabled: false,
    }
  },
  mounted() {
    var theme = window.localStorage.getItem('theme');
    if(theme) {
      this.theme = theme;
    }
    this.setTheme();
  },
  methods: {
    claim() {
      this.$store.state.stakeContract.harvest().then(() => {
        this.$store.dispatch('loadWallet');
      });
    },
    setTheme: function() {
      var theme = 'light';
      if(this.theme === 'true' || this.theme === true) {
        theme = 'dark';
      }
      window.localStorage.setItem('theme', this.theme);
      document.body.classList.remove('theme--light-theme');
      document.body.classList.remove('theme--dark-theme');
      document.body.classList.add('theme--' + theme + '-theme');
    },
  }
}
</script>